import { render, staticRenderFns } from "./AddCopy.vue?vue&type=template&id=3ffc7a28&scoped=true&"
import script from "./AddCopy.vue?vue&type=script&lang=js&"
export * from "./AddCopy.vue?vue&type=script&lang=js&"
import style0 from "./AddCopy.vue?vue&type=style&index=0&id=3ffc7a28&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3ffc7a28",
  null
  
)

export default component.exports